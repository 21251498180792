import { LOCALES } from '../locales';

export default {
  [LOCALES.BENAALI]: {
    'biodata-main-heading': 'ধাপ ২ : অনুগ্রহমূলক বায়োডাটা তৈরির জন্য ইংরাজী কী-বোর্ড ব্যবহার করুন এবং বিশদ বিবরণ পূরণ করুন দয়া করে',
    'lnk-translator':'টাইপিংয়ের জন্য বাংলা অনুবাদ থেকে বাংলা অনুবাদ ব্যবহার করুন',
    'heading-personal-details': 'ব্যক্তিগত বিবরণ',
    'heading-horoscope-details': 'রাশিফলের বিশদ',
    'heading-education-details': 'শিক্ষা & পেশার বিবরণ',
    'heading-family-details': 'পারিবারিক পটভূমির বিশদ',

    'heading-biodata': 'বায়োডাটা',
    'lbl-married': 'বিবাহিত',
    

    'lbl-fullname': 'পুরো নাম',
    'lbl-castsubcast': 'জাতি এবং উপকাস্ট',
    'lbl-complexion':'জটিলতা',
    'lbl-dateofbirth':"জন্ম তারিখ",
    'lbl-height':"উচ্চতা",
    'lbl-blood':"রক্তের গ্রুপ",
    'lbl-diet':"ডায়েট",
    'lbl-timeofbirth':"জন্মের সময়",
    'lbl-placeofbirth':"জন্মস্থান",
    'lbl-rashi':"রাশি",
    'lbl-devak':"দেবক / কুলদাইভাত / গোত্র",
    'lbl-education-details':"শিক্ষা বিস্তারিত",
    'lbl-incomedetails':"পেশা & আয়ের বিবরণ",
    'lbl-fathersfullname':"বাবার পুরো নাম",
    'lbl-mothername':"মায়ের নাম",
    'lbl-grandfatherfullname':"দাদার পুরো নাম",
    'lbl-contact1':"অভিভাবকদের যোগাযোগ নং",
    'lbl-contact2':"যোগাযোগ নং ২",
    'lbl-totbrothers': "টোটাল ব্রাদার্স",
    'lbl-marriedbrothers':"বিবাহিত ভাইয়েরা",
    'lbl-brothersfullname':"ভাইদের পুরো নাম",
    'lbl-totsisters':"মোট বোন",
    'lbl-marriedsisters':"বিবাহিত বোন",
    'lbl-sistersfullname':"বোনদের পুরো নাম",
    'lbl-raddress':"আবাসিক ঠিকানা",
    'lbl-tehasildistrict':"তহসিল ও জেলা",
    'lbl-fatheroccupation':"পিতার পেশা",
    'lbl-maternalname':"মামার পুরো নাম",
    'lbl-paternalname':"ফুফুর পুরো নাম",
    'lbl-surname':"আত্মীয়দের উপাধি",
    'lbl-expectationfrompartner':"অংশীদার থেকে প্রত্যাশা",


    'lbl-birthdate':"জন্ম তারিখ",
    'lbl-birthtime':"জন্মের সময়",
    'lbl-birthplace':"জন্মস্থান",
    'lbl-devakgotra':"কুলদাইবত",
    'lbl-cast':"জাত",
    'lbl-education':"শিক্ষা",
    'lbl-occupation':"পেশা",
    'lbl-familydetails':"পারিবারিক তথ্য",
    'lbl-grandfather':"দাদা",
    'lbl-fathersname':"বাবার নাম",
    'lbl-fathersoccp':"পিতার অধিপতি।",
    'lbl-brothers':"ভাই",
    'lbl-sisters':"বোনেরা",
    'lbl-mamasname':"মায়ের নাম",
    'lbl-auntname':"খালার নাম",
    'lbl-relatives':"আত্মীয়স্বজন",
    'lbl-expectations':"প্রত্যাশা",
    'lbl-parentscont':"যোগাযোগ",
    'lbl-address':"ঠিকানা",

    'lbl-ft':"ফুট",
    'lbl-in':"ইঞ্চি",

    'option-fair':"মেলা",
    'option-nimgora':"নিমগোড়া",
    'option-sawala':"সাওলা",
    'option-wheatish':"গম জাতীয়",

    'option-mesh':"জাল",
    'option-vrushabh':"বৃষভ",
    'option-mithun':"মিঠুন",
    'option-kark':"কার্ক",
    'option-sinh':"সিংহ",
    'option-kanya':"কন্যা",
    'option-tula':"তুলা",
    'option-vrushchik':"বৃশ্চিক",
    'option-dhanu':"ধনু",
    'option-makar':"মকর",
    'option-kumbha':"কুম্ভ",
    'option-meen':"মীন",

    'option-ap':"ক+",
    'option-am':"ক-",
    'option-bp':"খ+",
    'option-bm':"খ-",
    'option-abp':"এবি+",
    'option-abm':"এবি-",
    'option-op':"ও+",
    'option-om':"ও-",

    'option-veg':"নিরামিষাশী",
    'option-nonveg':"মাংসাশি",

    'lbl-am':"এএম",
    'lbl-pm':"পিএম",
  }
};