import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';

import {setActLanguage} from "../../state/biodataSlice";
import { CONSTANTS } from '../../constants/app';


export default function Languages(){

    const languages = useSelector((state)=>state.biodataSlice.languages)
    const actLanguage = useSelector((state)=>state.biodataSlice.actLanguage)

    const dispatch = useDispatch();

    const [language,setLanguage] = useState(actLanguage);


    const items = languages.map((item,i) => {
        return (
          <li key = {i} id={item['name']} onClick={(e)=>{setLanguage(item); dispatch(setActLanguage(item));}} className={`${language.name === item['name']?'active':''}`}>
             <img  src={item['img']} alt="ShaadiBiodata select languages" height={130}/>
             <span>{item['displayText']}</span>
          </li>
      )
    });

    return(
        <>
            <Row>
                <div className="heading">{CONSTANTS.BIODATA.headingSelLang}</div>
                <ul className="list-type nav-list language">
                    {items}
                </ul>
            </Row>
            <hr/>
        </>
  );
}