import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { CONSTANTS } from '../../constants/app';

import "./help.scss";

export default function Help(){
   const helpVideos = useSelector((state)=>state.biodataSlice.helpVideos);
   const Items = helpVideos.map((item) =>  <Col lg={6} md={6} xs={12} key={item.id}>
                                                <div className="mid-heading">
                                                   {item.heading}
                                                </div>
                                                <div className="video-responsive">
                                                   <iframe  src={item.link} title={item.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                </div>
                                             </Col>
                                            );

   return(
      <section>
         <Container>
            <Row><div className='heading'>{CONSTANTS.HEADER.lblHelp}</div></Row>
            <Row> {Items} </Row>
         </Container>
      </section>
 );
}
