
import { NavLink } from "react-router-dom";
import {CONSTANTS} from "../../constants/app";

export default function NavList(props){

    const navListItemClick = () => {
        props.navListItemClick();
    }
    return (
        <ul className={props.className}>
            <li onClick={()=>navListItemClick()}>
                <NavLink to="/home" className="storybook-link storybook-link-small" activeclassname="active">{CONSTANTS.HEADER.lblBiodata}</NavLink>
            </li>
            <li onClick={()=>navListItemClick()}>
                <NavLink to="/help" className="storybook-link storybook-link-small" activeclassname="active" >{CONSTANTS.HEADER.lblHelp}</NavLink>
            </li>
            <li onClick={()=>navListItemClick()}>
                <NavLink to="/contact" className="storybook-link storybook-link-small" activeclassname="active" >{CONSTANTS.HEADER.lblContact}</NavLink>
            </li>
            <li onClick={()=>navListItemClick()}>
                <NavLink to="/about" className="storybook-link storybook-link-small" activeclassname="active" >{CONSTANTS.FOOTER.lblAbout}</NavLink>
            </li>
        </ul>
    )
}