import React,{useState} from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import {IMAGES} from "../../constants/images";

import "./header.scss";
import NavList from './NavList';


export default function Header(){

  const [MMenu, setMMenu] = useState(false);

  const navListItemClick = () =>{
    setMMenu(false)
  }

  return (
        <>
            <header className="site-header">
              <Container>
                  <Row>
                      <Col md={3} sm={3} xs={4} className='logo-wrapper'>
                        <img src={IMAGES.logo} height={60} alt="shaadibiodata logo" title="ShaadiBiodataMaker"></img>
                      </Col>
                      <Col md={9} sm={9} xs={8}>
                        <ul className={`list-type custommenu`}>
                          <li onClick={(e)=>setMMenu(true)}>
                              <img src={IMAGES.menu} alt="shaadibiodata open" title="Open" />
                          </li>
                        </ul>
                        <NavList className="list-type nav-list" navListItemClick={navListItemClick}/>
                      </Col>
                  </Row>
              </Container>
            </header>
            <div className={`${MMenu === false?'dnone mobile-overlay':'mobile-overlay'}`}>
                <ul className="list-type custommenu">
                  <li onClick={(e)=>setMMenu(false)}>
                      <img src={IMAGES.close} alt="shaadibiodata close" title="Close"/>
                  </li>
                </ul>
                <NavList className="list-type" navListItemClick={navListItemClick}/>
           </div>
        </>
    );
}
