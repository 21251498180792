export const CONSTANTS = {
    COMMON:{
        appName:'ShaadiBiodata Maker',
        aboutCompany:'ShaadiBiodata provides marriage related online services since the year 2011. Using our online platform customer can create and download customized Wedding Biodata in English, Marathi, Hindi, Gujarati, Telugu, Bengali and Tamil. ShaadiBiodata have all the fields available which customers are looking into their biodata.',
        aboutCompany2023:'ShaadiBiodata released new features this year, customer can edit their saved biodata by using name. Also we did some modification in our ShaadiBiodata platform (Logo updated, Site layout changes with new template and more user friendly).'
    },
    BIODATA:{
        TemplateIdeas:'25+ BEST BIODATA DESIGN FORMAT IDEAS ON SHAADIBIODATA',
        headingTemplatewithphoto:'SHAADIBIODATA WITH PHOTOS',
        headingTemplate:'SHAADIBIODATA WITHOUT PHOTOS',
        headingSelLang:'STEP 1 : Select language for Biodata',
     },
    HEADER:{
        lblBiodata:'Biodata',
        lblHelp:'Help',
        lblContact:'Contact',
        lblContactUs:'Contact Us',
        lblAboutUs:'About Us',
     },
    FOOTER:{
       lblCopyrightYr:'2021',
       lblCopyrightTxt1:'Copyright Ⓒ',
       lblCopyrightTxt2:'All rights reserved.',
       lblAbout:'About',
       lblSteps:'Steps',
       lblQuickLinks:'Quick Links',
       lblSteps1:'Select Language',
       lblSteps2:'Fill Personal, Horoscope,',
       lblSteps3:'Education, Family Details',
       lblSteps4:'Select Design/Template',
       lblSteps5:'Edit & Preview Biodata',
       lblSteps6:'Download',
       lblPrivacyPolicy:'privacy policy',
       lblTerms:'terms of services'
    },
    LINKS:{
        lnkPrivacyPolicy:'https://portal.termshub.io/shaadibiodata.in/#privacy_policy',
        lnkTerms:'https://portal.termshub.io/shaadibiodata.in/website_tos/',
        lnkFacebook:'https://www.facebook.com/shaadibiodata0521',
        lnkYoutube:'https://www.youtube.com/channel/UCoC74E8buXS7Sjp6X27_vAA/about',
        lnkInstagram:'https://www.instagram.com/shaadibiodata/',
        lnkPinterest:'https://in.pinterest.com/shaadibiodata0521/'
    }
}