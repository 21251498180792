import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Row, Col } from 'react-bootstrap';
import { Input } from '../../stories/Input';

import { updateSelectedFormStyles } from "../../state/biodataSlice";
import translate from '../../i18n/translate';

import { numberToNumber, generateRange, formatData, hideReviewCompany } from "../../utils/util";

export default function BiodataForm(){

    const actLanguage = useSelector((state)=>state.biodataSlice.actLanguage)
    const selectedFormStyles = useSelector((state)=>state.biodataSlice.selectedFormStyles);

    const dispatch = useDispatch();
    const intl = useIntl();

    let years = generateRange(1965,2008);
        years = years.reverse();
    let month = generateRange(1,31);
    let mins = generateRange(0,59);
    let hrs = generateRange(1,12);

    const hrsOptions = formatData(hrs);
    const monthOptions = formatData(month);
    const yearsOptions = formatData(years);
    const minsOptions = formatData(mins);


    useEffect(()=>{
        careateBiodata();
        hideReviewCompany();
    },[selectedFormStyles,actLanguage]);

    const updateFormData = (e) =>{
        dispatch(updateSelectedFormStyles({
            ...selectedFormStyles,
            [e.currentTarget.name] : e.currentTarget.value
        }))
    }

    const manageLength=(e,maxLen)=>{
        let currEle = e.currentTarget.value;
        if(currEle.length > maxLen){
          e.currentTarget.value = currEle.substr(0,maxLen);
          return false;
        }else{
            updateFormData(e);
        }
    }
  
    const changeSelect=(e)=>{
        updateFormData(e);
    }
  
    const checkLength=(e)=> {
        let len = e.currentTarget.value.length;
        if (len !== 10 && len > 0) {
            alert("Invalide mobile length");
            e.currentTarget.value = "";
            e.currentTarget.focus();
            return false;
        }else{
        updateFormData(e);
        }
    }
  
  
    // On file select (from the pop up)
    const onFileChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        var FR= new FileReader();
        FR.addEventListener("load", function(e) {
          document.getElementById("uploadedImg").setAttribute("src", e.target.result);
          updateFormData(e);
        }); 
        FR.readAsDataURL( event.target.files[0] );
      }
    }

    const handleHeightForLanguages = (ht) => {
        const height = ht.split(' ');
        return numberToNumber(actLanguage.name,height[0])+" "+intl.formatMessage({id: 'lbl-ft'})+" "+numberToNumber(actLanguage.name,height[2])+" "+intl.formatMessage({id: 'lbl-in'});
    }

    const getBiodataLebels=()=>{
        return [
            intl.formatMessage({id: 'heading-personal-details'}),
            intl.formatMessage({id: 'lbl-fullname'}),
            intl.formatMessage({id: 'lbl-birthdate'}),
            intl.formatMessage({id: 'lbl-birthtime'}),
            intl.formatMessage({id: 'lbl-birthplace'}),
            intl.formatMessage({id: 'lbl-height'}),
            intl.formatMessage({id: 'lbl-rashi'}),
            intl.formatMessage({id: 'lbl-devakgotra'}),
            intl.formatMessage({id: 'lbl-blood'}),
            intl.formatMessage({id: 'lbl-complexion'}),
            intl.formatMessage({id: 'lbl-diet'}),
            intl.formatMessage({id: 'lbl-cast'}),
            intl.formatMessage({id: 'lbl-education'}),
            intl.formatMessage({id: 'lbl-occupation'}),
            intl.formatMessage({id: 'lbl-familydetails'}),
            intl.formatMessage({id: 'lbl-grandfather'}),
            intl.formatMessage({id: 'lbl-fathersname'}),
            intl.formatMessage({id: 'lbl-fathersoccp'}),
            intl.formatMessage({id: 'lbl-mothername'}),
            intl.formatMessage({id: 'lbl-brothers'}),
            intl.formatMessage({id: 'lbl-sisters'}),
            intl.formatMessage({id: 'lbl-mamasname'}),
            intl.formatMessage({id: 'lbl-auntname'}),
            intl.formatMessage({id: 'lbl-relatives'}),
            intl.formatMessage({id: 'lbl-expectations'}),
            intl.formatMessage({id: 'lbl-parentscont'}),
            intl.formatMessage({id: 'lbl-address'}),
        ];
    }

    const getBiodataFormData = () => {

        //get dropdown selected values 
        const isMarriad = intl.formatMessage({id: 'lbl-married'});
        const complexion = document.querySelector('select[name="complexion"]').value;

        const dayValue = document.querySelector('select[name="day"]').value;
        const monthValue = document.querySelector('select[name="month"]').value;
        const yearValue = document.querySelector('select[name="year"]').value;

        const height = document.querySelector('select[name="height"]').value;

        const bloodGroup = document.querySelector('select[name="bloodGroup"]').value;

        const diet = document.querySelector('select[name="diet"]').value;

        const hrsValue = document.querySelector('select[name="hrs"]').value;
        const minValue = document.querySelector('select[name="min"]').value;
        const ampmValue = document.querySelector('select[name="ampm"]').value;

        const rashi = document.querySelector('select[name="rashi"]').value;

        const brothers = document.querySelector('select[name="brothers"]').value;
        const marriedBrothers = document.querySelector('select[name="marriedBrothers"]').value;
        const sisters = document.querySelector('select[name="sisters"]').value;
        const marriedSisters = document.querySelector('select[name="marriedSisters"]').value;

        return ["",
                selectedFormStyles['fullName']?selectedFormStyles['fullName']:'',
                numberToNumber(actLanguage.name,dayValue)+"-"+numberToNumber(actLanguage.name,monthValue) +"-"+numberToNumber(actLanguage.name,yearValue),
                numberToNumber(actLanguage.name,hrsValue)+":"+numberToNumber(actLanguage.name,minValue)+" "+ampmValue,
                selectedFormStyles['birthPlace'],
                handleHeightForLanguages(height),
                rashi,
                selectedFormStyles['devak'],
                bloodGroup,
                complexion,
                diet,
                selectedFormStyles['caste2'],
                selectedFormStyles['educationDetails'],
                selectedFormStyles['occupation'],
                "",
                selectedFormStyles['grandfatherName'],
                selectedFormStyles['fatherName'],
                selectedFormStyles['fatherOccupation'],

                selectedFormStyles['motherName'],

                numberToNumber(actLanguage.name,brothers)+"/"+numberToNumber(actLanguage.name,marriedBrothers)+" "+isMarriad+"("+selectedFormStyles?.['brothersfullname']+")",
                numberToNumber(actLanguage.name,sisters)+"/"+numberToNumber(actLanguage.name,marriedSisters)+" "+isMarriad+"("+selectedFormStyles?.['sistersfullname']+")",

                selectedFormStyles['mamaDetails'],
                selectedFormStyles['auntName'],
                selectedFormStyles['relatives'],
                selectedFormStyles['expectations'],
                numberToNumber(actLanguage.name,selectedFormStyles['mobileNumber'])+" / "+numberToNumber(actLanguage.name,selectedFormStyles['mobileNumber2']),
                selectedFormStyles['residenceAddress']+", "+selectedFormStyles['addressLine2']
            ];

    }
    

    const careateBiodata = (e)=> {

        e?.preventDefault();
        let c = document.getElementById("shaadibiodata");
        let ctx = c.getContext("2d");
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, c.width, c.height);

        let arrTitle, arrDesc;
        let theme = selectedFormStyles['actTheme'];
        let y = 60;

        const profilePhoto = document.getElementById("uploadedImg").getAttribute("src");
        const isWatermark = false;//document.querySelector('.isWatermark').checked;
        let spaceBetLabelValue = 150;
        const profilePhotoTop = 100;
        const fontFamily = 'Verdana';
        const fontSizeMainHeading = '17px';
        const fontSizeHeading = '15px';
        const fontSizeLabel = '14px';
        const fontSizeWatermark = "11px";
        const color = 'black';

        if(actLanguage.name === "tamil"){
            spaceBetLabelValue = spaceBetLabelValue + 50;
        }else if(actLanguage.name === "telugu"){
            spaceBetLabelValue = spaceBetLabelValue + 10;
        }


        //Theme
        let bg = new Image();
        bg.src = "assets/themes/"+theme+".jpg";
        
        bg.onload = function()
        {
            ctx.drawImage(bg, 0, 0, 613, 793); // x,y,w,h
                    
            ctx.font = "bold "+fontSizeMainHeading+" "+fontFamily; 
            ctx.fillStyle = color;
            ctx.fillText(intl.formatMessage({id: 'heading-biodata'}), 245, 50);
            //Labels
            arrTitle = getBiodataLebels();
            //Label values  
            arrDesc  = getBiodataFormData();

            for (let i = 0; i < arrTitle.length; i++) { 
                
                if(i===0 || i===14) {
                    ctx.font = "bold "+fontSizeHeading+" "+fontFamily; 
                }else {
                    ctx.font = fontSizeLabel+" "+fontFamily; 
                }

                ctx.fillStyle = color;
                y = y+25;
                ctx.fillText(arrTitle[i], 40, y);
                
                if(i!==0 && i!==14) {
                    ctx.fillText(":", spaceBetLabelValue+5, y);//140
                }
                
                ctx.fillText(arrDesc[i], spaceBetLabelValue+15, y);//150
            }
            
            ctx.font = "bold "+fontSizeWatermark+" "+fontFamily; 
            ctx.fillStyle = color;

            if(isWatermark){
                ctx.fillText("Designed by https://shaadibiodata.in", 200, 763);
            }else{
                ctx.fillText("", 200, 770);
            }

            //profile photo
            if(profilePhoto!=="") {
                let image1 = new Image();
                image1.src = profilePhoto;
                
                image1.onload = function()
                {
                    //border to image
                    ctx.beginPath(); 
                    ctx.strokeStyle = '#000';  // some color/style
                    ctx.lineWidth = 1;

                    ctx.drawImage(image1, 370, profilePhotoTop, 210, 260); // x,y,w,h
                    ctx.strokeRect(370, profilePhotoTop, 210, 260);

                    let image  = c.toDataURL(); 

                    //save image in local storage in use it in success comp
                    localStorage.setItem("finalImage", image);
                }
            
            }else {
                let image  = c.toDataURL(); 
                //save image in local storage in use it in success comp
                localStorage.setItem("finalImage", image);
            }
        }
    }
    
    return (
        <Row>
            <form onSubmit={(e)=>careateBiodata(e)} id="shaadibiodataform">
                <Row>
                    <div className="notEmpty heading">{translate('biodata-main-heading')}</div>
                    {actLanguage.name !== 'english' && <a href={`https://translate.google.com/?hl=en&tab=TT&sl=en&tl=${actLanguage.code}&text=${actLanguage.name}&op=translate`} target="_blank" className='word-translate-link'>{translate('lnk-translator')}</a>}
                </Row>
                <Row><div className="mid-heading">{translate('heading-personal-details')}</div> <hr/></Row>
                <Row>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-fullname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="fullName" type="text"  onKeyUp={(e)=>manageLength(e,30)} required />
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-castsubcast')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="caste2" type="text"  onKeyUp={(e)=>manageLength(e,30)} required/>
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-complexion')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <select name="complexion" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="Fair">{translate('option-fair')}</option>
                            <option >{translate('option-nimgora')}</option>
                            <option >{translate('option-sawala')}</option>
                            <option >{translate('option-wheatish')}</option>
                            <option > - </option>
                        </select>
                    </Col>
                </Row>
                
                <Row>
                      <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-dateofbirth')}<span className="notEmpty">*</span></Col>
                      <Col lg={2} md={2} xs={6}>
                        <Row>
                            <Col lg={4} md={4} xs={4} >
                                <select name="day" required onChange={(e)=>changeSelect(e)}>
                                    <option defaultValue="01">01</option>
                                    {monthOptions}
                                </select>
                            </Col>
                            <Col lg={4} md={4} xs={4} >
                                <select name="month" required onChange={(e)=>changeSelect(e)}>
                                    <option defaultValue="01">01</option>
                                    {hrsOptions}
                                </select>
                            </Col>
                            <Col lg={4} md={4} xs={4} >
                                <select name="year" required onChange={(e)=>changeSelect(e)}>
                                    <option defaultValue="2008">2008</option>
                                    {yearsOptions}
                                </select>
                            </Col>
                        </Row>
                      </Col>
                      <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-height')}<span className="notEmpty">*</span></Col>
                      <Col lg={2} md={2} xs={6}>
                         <select name="height" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="4.05">4 ft 05 in</option>
                            <option defaultValue="4.06">4 ft 06 in</option>
                            <option defaultValue="4.07">4 ft 07 in</option>
                            <option defaultValue="4.08">4 ft 08 in</option>
                            <option defaultValue="4.09">4 ft 09 in</option>
                            <option defaultValue="4.10">4 ft 10 in</option>
                            <option defaultValue="4.11">4 ft 11 in</option>
                            <option defaultValue="5">5 ft 00 in</option>
                            <option defaultValue="5.01">5 ft 01 in</option>
                            <option defaultValue="5.02">5 ft 02 in</option>
                            <option defaultValue="5.03">5 ft 03 in</option>
                            <option defaultValue="5.04">5 ft 04 in</option>
                            <option defaultValue="5.05">5 ft 05 in</option>
                            <option defaultValue="5.06">5 ft 06 in</option>
                            <option defaultValue="5.07">5 ft 07 in</option>
                            <option defaultValue="5.08">5 ft 08 in</option>
                            <option defaultValue="5.09">5 ft 09 in</option>
                            <option defaultValue="5.10">5 ft 10 in</option>
                            <option defaultValue="5.11">5 ft 11 in</option>
                            <option defaultValue="6">6 ft 00 in</option>
                            <option defaultValue="6.01">6 ft 01 in</option>
                            <option defaultValue="6.02">6 ft 02 in</option>
                            <option defaultValue="6.03">6 ft 03 in</option>
                            <option defaultValue="6.04">6 ft 04 in</option>
                            <option defaultValue="6.05">6 ft 05 in</option>
                         </select>
                      </Col>
                      <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-blood')}<span className="notEmpty">*</span></Col>
                      <Col lg={2} md={2} xs={6}>
                         <select name="bloodGroup" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="A+">{translate('option-ap')}</option>
                            <option >{translate('option-am')}</option>
                            <option >{translate('option-bp')}</option>
                            <option >{translate('option-bm')}</option>
                            <option >{translate('option-abp')}</option>
                            <option >{translate('option-abm')}</option>
                            <option >{translate('option-op')}</option>
                            <option >{translate('option-om')}</option>
                         </select>
                      </Col>
                </Row> 
                 <Row>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-diet')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <select name="diet" onChange={(e)=>changeSelect(e)}>
                        <option defaultValue="Vegetarian">{translate('option-veg')}</option>
                        <option >{translate('option-nonveg')}</option>
                        <option > - </option>
                        </select>
                    </Col>
                </Row> 

                <Row><div className="mid-heading"> {translate('heading-horoscope-details')} </div> <hr/></Row>  
                <Row>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-timeofbirth')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Row>
                            <Col lg={4} md={4} xs={4}>
                                <select name="hrs"  onChange={(e)=>changeSelect(e)}> {hrsOptions}</select>
                            </Col>
                            <Col lg={4} md={4} xs={4}>
                                <select name="min"  onChange={(e)=>changeSelect(e)}>{minsOptions} </select>
                            </Col>
                            <Col lg={4} md={4} xs={4}>
                                <select name="ampm" onChange={(e)=>changeSelect(e)}>
                                    <option defaultValue="AM">am</option>
                                    <option>pm</option>
                                </select>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-placeofbirth')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="birthPlace" type="text" onKeyUp={(e)=>manageLength(e,30)} required/>
                    </Col>

                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-rashi')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <select name="rashi" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="Mesh">{translate('option-mesh')}</option>
                            <option>{translate('option-vrushabh')}</option>
                            <option>{translate('option-mithun')}</option>
                            <option>{translate('option-kark')}</option>
                            <option>{translate('option-sinh')}</option>
                            <option>{translate('option-kanya')}</option>
                            <option>{translate('option-tula')}</option>
                            <option>{translate('option-vrushchik')}</option>
                            <option>{translate('option-dhanu')}</option>
                            <option>{translate('option-makar')}</option>
                            <option>{translate('option-kumbha')}</option>
                            <option>{translate('option-meen')}</option>
                            <option> - </option>
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-devak')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="devak" type="text" onKeyUp={(e)=>manageLength(e,30)} required/>
                    </Col>
                </Row>
                <Row> <div className="mid-heading">{translate('heading-education-details')}</div> <hr/></Row>
                <Row> 
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-education-details')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="educationDetails" type="text" onKeyUp={(e)=>manageLength(e,30)} required/>
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-incomedetails')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="occupation" type="text" onKeyUp={(e)=>manageLength(e,30)} required/>
                    </Col>
                </Row>
                <Row> <div className="mid-heading">{translate('heading-family-details')}</div> <hr/></Row>
                <Row> 
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-fathersfullname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="fatherName" onKeyUp={(e)=>manageLength(e,54)} required/>
                    </Col>

                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-mothername')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="motherName" onKeyUp={(e)=>manageLength(e,54)} required/>
                    </Col>

                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-grandfatherfullname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="grandfatherName" onKeyUp={(e)=>manageLength(e,54)} required/>
                    </Col>
                </Row>
                <Row> 
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-contact1')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="mobileNumber" type="number" onBlur={(e)=>checkLength(e)} required/>
                    </Col>

                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-contact2')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input name="mobileNumber2" type="number" onBlur={(e)=>checkLength(e)} required/>
                    </Col>
                    
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-totbrothers')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <select name="brothers" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="0">0</option>
                            <option >1</option>
                            <option >2</option>
                            <option >3</option>
                        </select>
                    </Col>
                </Row>
                <Row> 
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-marriedbrothers')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <select name="marriedBrothers" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="0">0</option>
                            <option >1</option>
                            <option >2</option>
                            <option >3</option>
                        </select>
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-brothersfullname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                      <Input type="text" onKeyUp={(e)=>manageLength(e,70)} name="brothersfullname" required/>
                    </Col>
                    
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-totsisters')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <select name="sisters" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="0">0</option>
                            <option >1</option>
                            <option >2</option>
                            <option >3</option>
                            <option >4</option>
                        </select>
                    </Col>
                    
                </Row>
   
                <Row> 
                   <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-marriedsisters')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <select name="marriedSisters" onChange={(e)=>changeSelect(e)}>
                            <option defaultValue="0">0</option>
                            <option >1</option>
                            <option >2</option>
                            <option >3</option>
                            <option >4</option>
                        </select>
                    </Col>

                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-sistersfullname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                      <Input type="text" onKeyUp={(e)=>manageLength(e,70)} name="sistersfullname" required/>
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-fatheroccupation')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input type="text" onKeyUp={(e)=>manageLength(e,54)}  name="fatherOccupation" required/>
                    </Col>
                </Row>
                <Row> 
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-maternalname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input type="text" onKeyUp={(e)=>manageLength(e,70)} name="mamaDetails" required/>
                    </Col>

                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-paternalname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input type="text" onKeyUp={(e)=>manageLength(e,70)} name="auntName" required/>
                    </Col>

                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-surname')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input type="text" onKeyUp={(e)=>manageLength(e,70)} name="relatives" required/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-expectationfrompartner')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input type="text" onKeyUp={(e)=>manageLength(e,54)} name="expectations" required/>
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-raddress')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input type="text" onKeyUp={(e)=>manageLength(e,54)} name="residenceAddress" required/>
                    </Col>
                    <Col lg={2} md={2} xs={6} className="lebel">{translate('lbl-tehasildistrict')}<span className="notEmpty">*</span></Col>
                    <Col lg={2} md={2} xs={6}>
                        <Input type="text" onKeyUp={(e)=>manageLength(e,54)} name="addressLine2" required/>
                    </Col>
               </Row>
               <hr/>
               <Row className="upload-profile-photo">
                    <Col lg={3} md={3} xs={6}>Upload your profile photo (use 210x260 pixel image for best fit)</Col>
                    <Col lg={3} md={3} xs={6}>
                        <input type="file" onChange={(e)=>onFileChange(e)} name="myFile" accept="image/*"/>
                        <img id="uploadedImg" src="" className="dnone" alt="Marriage Biodata"/>
                    </Col>
               </Row>
               <hr/>
            </form>
        </Row>
    )
}
