import { LOCALES } from '../locales';

export default {
  [LOCALES.TELUGU]: {

    'biodata-main-heading': 'దశ ౨ : కైబోర్డును ఉపయోగించుకోండి మరియు అద్భుతమైన బయోడటాను సృష్టించడానికి వివరాల సమాచారాన్ని పూరించండి',
    'lnk-translator':'టైపింగ్ కోసం మరాఠీ అనువాదానికి గూగుల్ ఇంగ్లీష్ ఉపయోగించండి',
    'heading-personal-details': 'వ్యక్తిగత వివరాలు',
    'heading-horoscope-details': 'జాతకం వివరాలు',
    'heading-education-details': 'చదువు & వృత్తి వివరాలు',
    'heading-family-details': 'కుటుంబ నేపథ్య వివరాలు',

    'heading-biodata': 'బయోడేటా',
    'lbl-married': 'పెళ్లయింది',
    

    'lbl-fullname': 'పూర్తి పేరు',
    'lbl-castsubcast': 'కులం మరియు ఉపకులం',
    'lbl-complexion':'సంక్లిష్టత',
    'lbl-dateofbirth':"పుట్టిన తేది",
    'lbl-height':"ఎత్తు",
    'lbl-blood':"రక్తపు గ్రూపు",
    'lbl-diet':"ఆహారం",
    'lbl-timeofbirth':"పుట్టిన సమయం",
    'lbl-placeofbirth':"పుట్టిన స్థలం",
    'lbl-rashi':"రాశి",
    'lbl-devak':"దేవక్ / కుల్దైవత్ / గోత్ర",
    'lbl-education-details':"విద్య వివరాలు",
    'lbl-incomedetails':"వృత్తి & ఆదాయ వివరాలు",
    'lbl-fathersfullname':"తండ్రి పూర్తి పేరు",
    'lbl-mothername':"తల్లి పేరు",
    'lbl-grandfatherfullname':"తాతయ్య పూర్తి పేరు",
    'lbl-contact1':"తల్లిదండ్రులు సంప్రదించండి",
    'lbl-contact2':"సంప్రదింపు సంఖ్య 2",
    'lbl-totbrothers': "మొత్తం బ్రదర్స్",
    'lbl-marriedbrothers':"వివాహిత సోదరులు",
    'lbl-brothersfullname':"సోదరుల పూర్తి పేరు",
    'lbl-totsisters':"మొత్తం సిస్టర్స్",
    'lbl-marriedsisters':"వివాహిత సిస్టర్స్",
    'lbl-sistersfullname':"సిస్టర్స్ పూర్తి పేరు",
    'lbl-raddress':"నివాస చిరునామా",
    'lbl-tehasildistrict':"తహసీల్ మరియు జిల్లా",
    'lbl-fatheroccupation':"తండ్రి యొక్క వృత్తి",
    'lbl-maternalname':"మేనమామ పూర్తి పేరు",
    'lbl-paternalname':"తండ్రి అత్త పూర్తి పేరు",
    'lbl-surname':"బంధువుల ఇంటిపేరు",
    'lbl-expectationfrompartner':"భాగస్వామి నుండి అంచనాలు",


    'lbl-birthdate':"పుట్టిన తేదీ",
    'lbl-birthtime':"పుట్టిన సమయం",
    'lbl-birthplace':"పుట్టిన ప్రదేశం",
    'lbl-devakgotra':"కులదైవత్",
    'lbl-cast':"కులం",
    'lbl-education':"చదువు",
    'lbl-occupation':"వృత్తి",
    'lbl-familydetails':"కుటుంబ వివరాలు",
    'lbl-grandfather':"తాతయ్య",
    'lbl-fathersname':"తండ్రి పేరు",
    'lbl-fathersoccp':"తండ్రి యొక్క వృత్తి",
    'lbl-brothers':"సోదరులు",
    'lbl-sisters':"సోదరీమణులు",
    'lbl-mamasname':"అమ్మ పేరు",
    'lbl-auntname':"అత్త పేరు",
    'lbl-relatives':"బంధువులు",
    'lbl-expectations':"అంచనాలు",
    'lbl-parentscont':"పరిచయాలు",
    'lbl-address':"చిరునామా",

    'lbl-ft':"అడుగులు",
    'lbl-in':"అంగుళం",

    'option-fair':"న్యాయమైన",
    'option-nimgora':"నిమ్గోరా",
    'option-sawala':"సవాలా",
    'option-wheatish':"గోధుమరంగు",

    'option-mesh':"మెష్",
    'option-vrushabh':"వృషభ్",
    'option-mithun':"మిథున్",
    'option-kark':"కార్క్",
    'option-sinh':"సిన్హ్",
    'option-kanya':"కన్యా",
    'option-tula':"తుల",
    'option-vrushchik':"వృశ్చిక్",
    'option-dhanu':"ధను",
    'option-makar':"మకర్",
    'option-kumbha':"కుంభము",
    'option-meen':"మీన్",

    'option-ap':"ఎ+",
    'option-am':"ఎ-",
    'option-bp':"బి+",
    'option-bm':"బి-",
    'option-abp':"ఎబి+",
    'option-abm':"ఎబి-",
    'option-op':"ఓ+",
    'option-om':"ఓ-",

    'option-veg':"శాఖాహారం",
    'option-nonveg':"నాన్ వెజిటేరియన్",

    'lbl-am':"AM",
    'lbl-pm':"PM",
  }
};