import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from 'react-redux';
import { jsPDF } from "jspdf";

import {Button} from "../../stories/Button";
import {Input} from "../../stories/Input";

import {APP_API_BASEURL} from "../../config";

import {updateSelectedFormStyles} from "../../state/biodataSlice";

import { validateForm } from "../../utils/util";

const responsiveTheme = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};

export default function Preview(props){
    const selectedFormStyles = useSelector((state)=>state.biodataSlice.selectedFormStyles);
    const actTheme = useSelector((state)=>state.biodataSlice.selectedFormStyles.actTheme)
    const themes = useSelector((state)=>state.biodataSlice.themes)
    const payForBiodata = useSelector((state)=>state.biodataSlice.payForBiodata);
    const actLanguage = useSelector((state)=>state.biodataSlice.actLanguage);
    const paymentURL = useSelector((state)=>state.biodataSlice.paymentURL);

    const dispatch = useDispatch();

    const items = themes.map((item,i) => {
          return (
            <div key={i}>
              <img id={item['name']} className={`${actTheme === item['name']?'active':''} theme`} src={item['img']} onClick={(e)=> changeTheme(e)} alt={item['alt']}/>
            </div>
        )
    });

    const changeTheme=(e)=>{
      dispatch(updateSelectedFormStyles({
          ...selectedFormStyles,
          ['actTheme'] : e.currentTarget.id
      }))
    }

    const saveBiodata = () => {
      /*this.setState({ pageLoader:"block"});*/

      const isValid = validateForm();

      if(isValid == false)
      return false;

      const finalPreviewImage = localStorage.getItem("finalImage");
      const url = APP_API_BASEURL+'api/save_biodata.php';

      if(payForBiodata === false){
        downloadPDF();
      }

      fetch(url,{
          method:'POST',
          headers:{
              'Accept':'application/json',
              'Content-Type':'application/json',
              'X-Requested-With':'XMLHttpRequest',
          },
          body: JSON.stringify({
            finalImage:finalPreviewImage,
            actLanguage:actLanguage.name,
            fullName:selectedFormStyles['fullName'],
            address:selectedFormStyles['residenceAddress']+", "+selectedFormStyles['addressLine2'],
            selectedFormStyles:selectedFormStyles
          })
      })
      .then((data) => {
          console.log("success",data.status)
          if(data.status === 200){
            if(payForBiodata === true){//redirect to payment page
              window.location.href = paymentURL;
            }
          }

          props.updateBiodataCount();
      })
    }

    const downloadPDF = () => {
        const data = localStorage.getItem("finalImage");
        const doc = new jsPDF();
        doc.addImage(data, 'PNG', 25, 25);
        doc.save('shaadibiodata_in.pdf');
    }

    return(
      <section>
          <Container>
            <Row>
              <div className="heading">Step 3 : Select designs for biodata, edit, preview and download</div>
              <Carousel responsive={responsiveTheme} autoPlay={true} infinite={true}>
                {items}
              </Carousel>
            </Row>
            <Row>
                <Col lg={3} md={3} xs={0}></Col>
                <Col lg={6} md={6} xs={12}>
                   <canvas id="shaadibiodata" width="612" height="792"></canvas>
                </Col>
                <Col lg={3} md={3} xs={0}></Col>
            </Row>
            <Row>
                <Col lg={4} md={4} xs={0}></Col>
                <Col lg={4} md={4} xs={12} className="mb-2 dnone">
                    <input type="checkbox" defaultChecked readOnly disabled required/> 
                    I accept all the 
                    <a href="https://portal.termshub.io/shaadibiodata.in/website_tos/" target="_blank">
                    &nbsp;terms &amp; conditions
                    </a>
                    <Input type="hidden" id="from" name="from" defaultValue="shaadibiodata"/>
                </Col>
                <Col lg={4} md={4} xs={0}></Col>
            </Row>
            <Row>
                <Col lg={4} md={4} xs={0}></Col>
                <Col lg={4} md={4} xs={12}>
                  <Button onClick={(e)=>saveBiodata(e)}>Download Biodata</Button>
                  {/* {payForBiodata === true && 'Pay Rs 49/- and '}  */}
                </Col>
                <Col lg={4} md={4} xs={0}></Col>
            </Row>
          </Container>
      </section>
  );
}