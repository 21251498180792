import { LOCALES } from '../locales';

export default {
  [LOCALES.ENGLISH]: {

    'biodata-main-heading': 'STEP 2 : PLEASE USE ENGLISH KEYBOARD AND FILL DETAIL INFORMATION TO CREATE IMPRESSIVE BIODATA',
    'lnk-translator':'empty for english',
    'heading-personal-details': 'Personal Details',
    'heading-horoscope-details': 'HOROSCOPE DETAILS',
    'heading-education-details': 'EDUCATION & OCCUPATION DETAILS',
    'heading-family-details': 'FAMILY BACKGROUND DETAILS',

    'heading-biodata': 'Biodata',
    'lbl-married': 'Married',
    

    'lbl-fullname': 'Full Name',
    'lbl-castsubcast': 'Caste and Subcaste',
    'lbl-complexion':'Complexion',
    'lbl-dateofbirth':"Date of Birth",
    'lbl-height':"Height",
    'lbl-blood':"Blood Group",
    'lbl-diet':"Diet",
    'lbl-timeofbirth':"Time of birth",
    'lbl-placeofbirth':"Place of Birth",
    'lbl-rashi':"Rashi",
    'lbl-devak':"Devak / Kuldaivat / Gotra",
    'lbl-education-details':"Education Details",
    'lbl-incomedetails':"Occupation & Income Details",
    'lbl-fathersfullname':"Fathers Full Name",
    'lbl-mothername':"Mothers Name",
    'lbl-grandfatherfullname':"Grandfathers Full Name",
    'lbl-contact1':"Parents Contact Number",
    'lbl-contact2':"Contact Number 2",
    'lbl-totbrothers': "Total Brothers",
    'lbl-marriedbrothers':"Married Brothers",
    'lbl-brothersfullname':"Brothers Full Name",
    'lbl-totsisters':"Total Sisters",
    'lbl-marriedsisters':"Married Sisters",
    'lbl-sistersfullname':"Sisters Full Name",
    'lbl-raddress':"Residential Address",
    'lbl-tehasildistrict':"Tehasil and District",
    'lbl-fatheroccupation':"Fathers Occupation",
    'lbl-maternalname':"Maternal Uncle Full Name",
    'lbl-paternalname':"Paternal Aunt Full Name",
    'lbl-surname':"Surname of Relatives",
    'lbl-expectationfrompartner':"Expectations from Partner",


    'lbl-birthdate':"Birth Date",
    'lbl-birthtime':"Birth Time",
    'lbl-birthplace':"Birth Place",
    'lbl-devakgotra':"Kuldaivat",
    'lbl-cast':"Caste",
    'lbl-education':"Education",
    'lbl-occupation':"Occupation",
    'lbl-familydetails':"Family Details",
    'lbl-grandfather':"Grandfather",
    'lbl-fathersname':"Father’s Name",
    'lbl-fathersoccp':"Father’s Occp.",
    'lbl-brothers':"Brothers",
    'lbl-sisters':"Sisters",
    'lbl-mamasname':"Mama’s Name",
    'lbl-auntname':"Aunt Name",
    'lbl-relatives':"Relatives",
    'lbl-expectations':"Expectations",
    'lbl-parentscont':"Parent’s Cont.",
    'lbl-address':"Address",

    'lbl-ft':"ft",
    'lbl-in':"in",

    'option-fair':"Fair",
    'option-nimgora':"Nimgora",
    'option-sawala':"Sawala",
    'option-wheatish':"Wheatish",

    'option-mesh':"Mesh",
    'option-vrushabh':"Vrushabh",
    'option-mithun':"Mithun",
    'option-kark':"Kark",
    'option-sinh':"Sinh",
    'option-kanya':"Kanya",
    'option-tula':"Tula",
    'option-vrushchik':"Vrushchik",
    'option-dhanu':"Dhanu",
    'option-makar':"Makar",
    'option-kumbha':"Kumbha",
    'option-meen':"Meen",

    'option-ap':"A+",
    'option-am':"A-",
    'option-bp':"B+",
    'option-bm':"B-",
    'option-abp':"AB+",
    'option-abm':"AB-",
    'option-op':"O+",
    'option-om':"O-",

    'option-veg':"Vegetarian",
    'option-nonveg':"Non Vegetarian",

    'lbl-am':"AM",
    'lbl-pm':"PM",
  }
};