import { LOCALES } from '../locales';

export default {
  [LOCALES.TAMIL]: {

    'biodata-main-heading': 'படி ௨ : ஈர்க்கக்கூடிய பயோடேட்டாவை உருவாக்க ஆங்கில விசைப்பலகை மற்றும் விவரம் தகவல்களை நிரப்பவும்',
    'lnk-translator':'தட்டச்சு செய்ய கூகிள் ஆங்கிலத்திலிருந்து தமிழ் மொழிபெயர்ப்பைப் பயன்படுத்தவும்',
    'heading-personal-details': 'தனிப்பட்ட விவரங்கள்',
    'heading-horoscope-details': 'ஜாதக விவரங்கள்',
    'heading-education-details': 'கல்வி & தொழில் விவரங்கள்',
    'heading-family-details': 'குடும்ப பின்னணி விவரங்கள்',

    'heading-biodata': 'பயோடேட்டா',
    'lbl-married': 'திருமணம்',
    

    'lbl-fullname': 'முழு பெயர்',
    'lbl-castsubcast': 'சாதி மற்றும் துணை சாதி',
    'lbl-complexion':'சிக்கலானது',
    'lbl-dateofbirth':"பிறந்த தேதி",
    'lbl-height':"உயரம்",
    'lbl-blood':"இரத்த வகை",
    'lbl-diet':"உணவுமுறை",
    'lbl-timeofbirth':"பிறந்த நேரம்",
    'lbl-placeofbirth':"பிறந்த இடம்",
    'lbl-rashi':"ராசி",
    'lbl-devak':"தேவக் / குல்தைவத் / கோத்ரா",
    'lbl-education-details':"கல்வி விவரங்கள்",
    'lbl-incomedetails':"தொழில் & வருமான விவரங்கள்",
    'lbl-fathersfullname':"தந்தையின் முழு பெயர்",
    'lbl-mothername':"அம்மாவின் பெயர்",
    'lbl-grandfatherfullname':"தாத்தா முழு பெயர்",
    'lbl-contact1':"பெற்றோர் தொடர்பு எண்",
    'lbl-contact2':"தொடர்பு எண் 2",
    'lbl-totbrothers': "மொத்த சகோதரர்கள்",
    'lbl-marriedbrothers':"திருமணமான சகோதரர்கள்",
    'lbl-brothersfullname':"சகோதரர்களின் முழு பெயர்",
    'lbl-totsisters':"மொத்த சகோதரிகள்",
    'lbl-marriedsisters':"திருமணமான சகோதரிகள்",
    'lbl-sistersfullname':"சகோதரிகளின் முழு பெயர்",
    'lbl-raddress':"வீட்டு முகவரி",
    'lbl-tehasildistrict':"தாலுகா மற்றும் மாவட்டம்",
    'lbl-fatheroccupation':"தந்தையர் தொழில்",
    'lbl-maternalname':"தாய் மாமா முழு பெயர்",
    'lbl-paternalname':"தந்தைவழி அத்தையின் முழு பெயர்",
    'lbl-surname':"உறவினர்களின் குடும்பப்பெயர்",
    'lbl-expectationfrompartner':"கூட்டாளரிடமிருந்து எதிர்பார்ப்புகள்",


    'lbl-birthdate':"பிறந்த தேதி",
    'lbl-birthtime':"பிறந்த நேரம்",
    'lbl-birthplace':"பிறந்த இடம்",
    'lbl-devakgotra':"குல்தைவத்",
    'lbl-cast':"சாதி",
    'lbl-education':"கல்வி",
    'lbl-occupation':"தொழில்",
    'lbl-familydetails':"குடும்ப விவரங்கள்",
    'lbl-grandfather':"தாத்தா",
    'lbl-fathersname':"தந்தையின் பெயர்",
    'lbl-fathersoccp':"தந்தையின் தொழில்",
    'lbl-brothers':"சகோதரர்கள்",
    'lbl-sisters':"சகோதரிகள்",
    'lbl-mamasname':"அம்மாவின் பெயர்",
    'lbl-auntname':"அத்தையின் பெயர்",
    'lbl-relatives':"உறவினர்கள்",
    'lbl-expectations':"எதிர்பார்ப்புகள்",
    'lbl-parentscont':"தொடர்பு",
    'lbl-address':"முகவரி",

    'lbl-ft':"அடி",
    'lbl-in':"அங்குலம்",

    'option-fair':"நியாயமான",
    'option-nimgora':"நிம்கோரா",
    'option-sawala':"சவாலா",
    'option-wheatish':"கோதுமை கலந்த",

    'option-mesh':"கண்ணி",
    'option-vrushabh':"வ்ருஷப்",
    'option-mithun':"மிதுன்",
    'option-kark':"கார்க்",
    'option-sinh':"சின்",
    'option-kanya':"கன்யா",
    'option-tula':"துலா",
    'option-vrushchik':"வ்ருஷ்சிக்",
    'option-dhanu':"தனு",
    'option-makar':"மகர்",
    'option-kumbha':"கும்பம்",
    'option-meen':"மீன்",

    'option-ap':"ஏ+",
    'option-am':"ஏ-",
    'option-bp':"பி+",
    'option-bm':"பி-",
    'option-abp':"ஏபி+",
    'option-abm':"ஏபி-",
    'option-op':"ஓ+",
    'option-om':"ஓ-",

    'option-veg':"சைவம்",
    'option-nonveg':"அசைவம்",

    'lbl-am':"நான்",
    'lbl-pm':"மாலை",
  }
};