import { Row,Col } from "react-bootstrap";
import { CONSTANTS } from "../../constants/app";
import { Link } from "../../stories/Link";
import { Label } from "../../stories/Label";

import "./footer.scss";

export default function Footer(){
  return (
    
    <footer className="site-footer">
      {/* Review Container */}
      <div className="elfsight-app-c374fd4e-83a2-47df-9df7-72bfcd07bd47"></div>
      <Row>
        <Col md={6} sm={6} xs={12}>
            <h6 className="bold">{CONSTANTS.FOOTER.lblAbout}</h6>
            <p className="about-company">{CONSTANTS.COMMON.aboutCompany}</p>
        </Col>
        <Col md={3} sm={3} xs={12}>
            <h6 className="bold">{CONSTANTS.FOOTER.lblSteps}</h6>
            <ul className="steps list-type">
                <li><Label>{CONSTANTS.FOOTER.lblSteps1}</Label></li>
                <li><Label>{CONSTANTS.FOOTER.lblSteps2}</Label></li>
                <li><Label>{CONSTANTS.FOOTER.lblSteps3}</Label></li>
                <li><Label>{CONSTANTS.FOOTER.lblSteps4}</Label></li>
                <li><Label>{CONSTANTS.FOOTER.lblSteps5}</Label></li>
                <li><Label>{CONSTANTS.FOOTER.lblSteps6}</Label></li>
            </ul>
        </Col>
        <Col md={3} sm={3} xs={12}>
            <h6 className="bold">{CONSTANTS.FOOTER.lblQuickLinks}</h6>
            <ul className="quick-links list-type">
              <li><Link href={CONSTANTS.LINKS.lnkPrivacyPolicy} target="_blank">{CONSTANTS.FOOTER.lblPrivacyPolicy}</Link></li>
              <li><Link href={CONSTANTS.LINKS.lnkTerms} target="_blank">{CONSTANTS.FOOTER.lblTerms}</Link></li>
            </ul>
        </Col>

      </Row>
      <Row className="site-footer-bottom">
        <Col md={9} sm={9} xs={12} className="copyright-text">
          {CONSTANTS.FOOTER.lblCopyrightTxt1} {CONSTANTS.FOOTER.lblCopyrightYr} <b>&nbsp;{CONSTANTS.COMMON.appName}&nbsp;</b> {CONSTANTS.FOOTER.lblCopyrightTxt2}
        </Col>
        <Col md={3} sm={3} xs={12}>
            <ul className="social nav-list">
                <li><Link target="_blank" href={CONSTANTS.LINKS.lnkInstagram} size={'small'}><i className="fa fa-instagram"></i></Link></li>
                <li><Link target="_blank" href={CONSTANTS.LINKS.lnkYoutube} size={'small'}><i className="fa fa-youtube"></i></Link></li>
                <li><Link target="_blank" href={CONSTANTS.LINKS.lnkFacebook} size={'small'}><i className="fa fa-facebook"></i> </Link></li>
                <li><Link target="_blank" href={CONSTANTS.LINKS.lnkPinterest} size={'small'}><i className="fa fa-pinterest"></i> </Link></li>
            </ul>
        </Col>
      </Row>
    </footer>
  )
}