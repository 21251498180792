import React from 'react';
import { useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { CONSTANTS } from '../../constants/app';

import "./about.scss";

export default function About(){

    const companyLife =  useSelector((state) => state.biodataSlice.companyLife);
    const Years = companyLife.map((item) => <> 
                                                <Row key={item.id}>
                                                    <Col lg={1} md={1} xs={3}>
                                                        <div className="image-wrapper">
                                                             <img src={item.logo}  height="100" alt="download free marriage biodata"/>
                                                        </div>
                                                    </Col>
                                                    <Col className="year content-center" lg={1} md={1} xs={3}>{item.year}</Col>
                                                </Row>
                                                <Row key={`sub_${item.id}`} className="about-content-warpper">
                                                    <Col lg={1} md={1} xs={3}>
                                                        <div className="line-container"></div>
                                                    </Col>
                                                    <Col lg={4} md={4} xs={9}>
                                                        {item.about}
                                                    </Col>
                                                </Row> 
                                                </>
                                            );
  return(
        <section>
            <Container>
                <Row><div className='heading'>{CONSTANTS.HEADER.lblAboutUs}</div></Row>
                {Years}
            </Container>
        </section>
    );
}

