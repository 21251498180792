import { createSlice } from "@reduxjs/toolkit";

import { CONSTANTS } from "../constants/app";
import { REACT_APP_PayForBiodata } from "../config";

const initialState = {
    actLanguage:{id:100, code:"en", name:"english",displayText:"English",img:"assets/languages/empty.png"},
    payForBiodata:REACT_APP_PayForBiodata,//true => pay 49, false=free
    paymentURL:'https://pmny.in/6InJWkAQEP7W',//true => pay 49, false=free
    totalBiodataCreated:"2400+",
    companyLife:[
        {"id":2021,"year":"2021","logo":'assets/logos/shaadibiodata_2021.png',"about":CONSTANTS.COMMON.aboutCompany},
        {"id":2023,"year":"2023","logo":'assets/logos/logo_red.jpeg',"about":CONSTANTS.COMMON.aboutCompany2023},
    ],
    helpVideos:[
        {'id':1,"heading":'how to create and download free marriage biodata in english.',"title":'download free marriage biodata in english',"link":'https://www.youtube.com/embed/1taIptS08XA'},
        {'id':2,"heading":'how to create and download free marriage biodata in hindi.',"title":'download free marriage biodata in hindi',"link":'https://www.youtube.com/embed/WtqCTqb0ACY'},
        {'id':3,"heading":'how to create and download free marriage biodata in marathi.',"title":'download free marriage biodata in marathi',"link":'https://www.youtube.com/embed/7u_nm9eUNvs'},
        {'id':4,"heading":'how to create and download free marriage biodata in gujarati.',"title":'download free marriage biodata in gujarati',"link":'https://www.youtube.com/embed/F0eZy5TXTxE'},
        {'id':5,"heading":'how to create and download free marriage biodata in bengali.',"title":'download free marriage biodata in bengali',"link":'https://www.youtube.com/embed/qv7-Us-3w8Y'},
        {'id':6,"heading":'how to create and download free marriage biodata in tamil.',"title":'download free marriage biodata in marathi',"link":'https://www.youtube.com/embed/wsL0OInNZ3g'},
        {'id':7,"heading":'how to create and download free marriage biodata in telugu.',"title":'download free marriage biodata in telugu',"link":'https://www.youtube.com/embed/BJ81v9B2rMQ'}
    ],
    biodataPreviewTemplate:[
        {id:1, name:"format 1",img:"assets/biodata_preview_templates/english.png",alt:"english wedding biodata"},
        {id:2, name:"format 1",img:"assets/biodata_preview_templates/marathi.png",alt:"gujarati marriage biodata"},
        {id:3, name:"format 1",img:"assets/biodata_preview_templates/hindi.png",alt:"girls marriage biodata"},
         {id:4, name:"format 1",img:"assets/biodata_preview_templates/gujarati.png",alt:"marathi wedding biodata"},
        {id:5, name:"format 1",img:"assets/biodata_preview_templates/bengali.png",alt:"boys wedding biodata"},
        {id:7, name:"format 1",img:"assets/biodata_preview_templates/tamil.png",alt:"tamil wedding biodata"},
        {id:8, name:"format 1",img:"assets/biodata_preview_templates/telugu.png",alt:"telugu wedding biodata"},
    ],
    biodataPreviewTemplateWithPhoto:[
        {id:1, name:"format 1",img:"assets/biodata_preview_templates/english_with_photo.png",alt:"english wedding biodata"},
        {id:2, name:"format 1",img:"assets/biodata_preview_templates/english_with_photo_girl.png",alt:"gujarati marriage biodata"},
        {id:3, name:"format 1",img:"assets/biodata_preview_templates/marathi_with_photo.png",alt:"girls marriage biodata"},
         {id:4, name:"format 1",img:"assets/biodata_preview_templates/marathi_with_photo_girl.png",alt:"marathi wedding biodata"},
        {id:5, name:"format 1",img:"assets/biodata_preview_templates/hindi_with_photo.png",alt:"boys wedding biodata"},
        {id:6, name:"format 1",img:"assets/biodata_preview_templates/hindi_with_photo_girl.png",alt:"hindi shaadi biodata"},
        {id:8, name:"format 1",img:"assets/biodata_preview_templates/gujarati_with_photo_girl.png",alt:"telugu wedding biodata"},
        {id:9, name:"format 1",img:"assets/biodata_preview_templates/bengali_with_photo.png",alt:"hindu wedding biodata"},
        {id:10, name:"format 1",img:"assets/biodata_preview_templates/bengali_with_photo_girl.png",alt:"make biodata Online free"},
        {id:10, name:"format 1",img:"assets/biodata_preview_templates/tamil_with_photo.png",alt:"make biodata Online free"},
        {id:10, name:"format 1",img:"assets/biodata_preview_templates/telugu_with_photo_girl.png",alt:"make biodata Online free"},

    ],
    languages:[
        {id:100, code:"en", name:"english",displayText:"English",img:"assets/languages/empty.png"},
        {id:200, code:"mr", name:"marathi",displayText:"मराठी",img:"assets/languages/empty.png"},
        {id:300, code:"hi", name:"hindi",displayText:"हिंदी",img:"assets/languages/empty.png"},
        {id:400, code:"gu", name:"gujarati",displayText:"ગુજરાતી",img:"assets/languages/empty.png"},
        {id:500, code:"bn", name:"benaali",displayText:"বাংলা",img:"assets/languages/empty.png"},
        {id:600, code:"ta", name:"tamil",displayText:"தமிழ்",img:"assets/languages/empty.png"},
        {id:700, code:"te", name:"telugu",displayText:"తెలుగు",img:"assets/languages/empty.png"}
    ],
    selectedFormStyles:{
        actTheme:"theme16",
        fullName:"",
        caste2:"",
        day:"01",
        month:'01',
        year:"2008",
        height:"4 ft 05 in",
        bloodGroup:"A+",
        complexion:"Fair",
        diet:"Vegetarian",
        hrs:"01",
        min:"00",
        ampm:"AM",
        birthPlace:"",
        rashi:"",
        devak:"",
        education:"",
        educationDetails:"",
        occupation:"",
        occupation2:"",
        fatherName:"",
        motherName:"",
        grandfatherName:"",
        brothers:"0",
        marriedBrothers:"0",
        brothersfullname:'',
        sisters:"0",
        marriedSisters:"0",
        sistersfullname:'',
        mobileNumber:"",
        mobileNumber2:"",
        residenceAddress:"",
        addressLine2:"",
        fatherOccupation:"",
        mamaDetails:"",
        auntName:"",
        relatives:"",
        expectations:""
    },
    themes:[
      {id:1, name:"theme20",img:"assets/themes/theme20.jpg",alt:"wedding biodata"},
      {id:2, name:"theme19",img:"assets/themes/theme19.jpg",alt:"biodata maker"},
      {id:3, name:"theme18",img:"assets/themes/theme18.jpg",alt:"matrimonial biodata for male"},
      {id:4, name:"theme17",img:"assets/themes/theme17.jpg",alt:"matrimonial biodata for female"},
      {id:4, name:"theme16",img:"assets/themes/theme16.jpg",alt:"matrimonial biodata for girl"},
      {id:5, name:"theme29",img:"assets/themes/theme29.jpg",alt:"wedding biodata"},
      {id:6, name:"theme28",img:"assets/themes/theme28.jpg",alt:"biodata for marriage"},
      {id:7, name:"theme27",img:"assets/themes/theme27.jpg",alt:"biodata for marriage boys"},
      {id:8, name:"theme26",img:"assets/themes/theme26.jpg",alt:"english biodata for marriage"},
      {id:9, name:"theme25",img:"assets/themes/theme25.jpg",alt:"marathi biodata for marriage"},
      {id:10, name:"theme24",img:"assets/themes/theme24.jpg",alt:"hindi biodata for marriage"},
      {id:11, name:"theme23",img:"assets/themes/theme23.jpg",alt:"hindu biodata for marriage"},
     
      {id:12, name:"theme20",img:"assets/themes/theme20.jpg",alt:"muslim biodata for marriage"},
      {id:13, name:"theme19",img:"assets/themes/theme19.jpg",alt:"wedding biodata"},
      {id:14, name:"theme18",img:"assets/themes/theme18.jpg",alt:"girls biodata for marriage"},
      {id:15, name:"theme17",img:"assets/themes/theme17.jpg",alt:"wedding biodata"},
      {id:16, name:"theme16",img:"assets/themes/theme16.jpg",alt:"wedding biodata"},
      
      {id:17, name:"theme14",img:"assets/themes/theme14.jpg",alt:"wedding biodata"},
      {id:18, name:"theme11",img:"assets/themes/theme11.jpg",alt:"wedding biodata"},
      {id:19, name:"theme10",img:"assets/themes/theme10.jpg",alt:"wedding biodata"},

      {id:20, name:"theme9",img:"assets/themes/theme9.jpg",alt:"shaadi ka biodata"},
      {id:21, name:"theme2",img:"assets/themes/theme2.jpg",alt:"lagncha biodata"},
      {id:22, name:"theme3",img:"assets/themes/theme3.jpg",alt:"wedding biodata"},
      {id:23, name:"theme6",img:"assets/themes/theme6.jpg",alt:"wedding biodata"},
      {id:24, name:"theme7",img:"assets/themes/theme7.jpg",alt:"wedding biodata"},
      {id:26, name:"theme8",img:"assets/themes/theme8.jpg",alt:"wedding biodata"},
      {id:27, name:"theme15",img:"assets/themes/theme15.jpg",alt:"wedding biodata"}
    ],
}
const biodataSlice = createSlice({
    name:"biodata",
    initialState,
    reducers:{
        setActLanguage(state = initialState,action){
            return {...state,actLanguage:action.payload}
        },
        updateSelectedFormStyles(state = initialState,action){
            return {...state,selectedFormStyles:{ ...state.selectedFormStyles,...action.payload}}
        },
        setTotalBiodataCreated(state = initialState,action){
            return {...state,totalBiodataCreated:action.payload}
        }
    }
})

export const {setActLanguage, updateSelectedFormStyles, setTotalBiodataCreated} = biodataSlice.actions;
export default biodataSlice.reducer;