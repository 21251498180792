import React from 'react';
import './link.scss';

/**
 * Primary UI component for user interaction
 */
export const Link = ({size,className, ...props }) => {
  return (
    <a className={['storybook-link', `storybook-link-${size}`,`${className?className:''}`].join(' ')}
      {...props}>
    {props.children}
    </a>
  );
};
