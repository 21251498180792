import en from './en-US';
import mr from './mr-MR';//Marathi
import hi from './hi-HI';//Hindi
import gu from './gu-GU';//Gujarati
import bn from './bn-BN';//bengali  
import ta from './ta-TA';//Tamil  
import te from './te-TE';//telugu  

export default {
  ...en,
  ...mr,
  ...hi,
  ...gu,
  ...bn,
  ...ta,
  ...te,
};