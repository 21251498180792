import React from 'react';
import './label.scss';

/**
 * Primary UI component for user interaction
 */
export const Label = ({size,className, ...props }) => {
  return (
    <label className={['storybook-label', `${className?className:''}`].join(' ')}
      {...props}>
    {props.children}
    </label>
  );
};

