import { LOCALES } from '../locales';

export default {
  [LOCALES.GUJARATI]: {

    'biodata-main-heading': 'પગલું ૨ : પ્રભાવશાળી બાયોડેટા બનાવવા માટે કૃપા કરીને ગુજરતી કીબોર્ડનો ઉપયોગ કરો અને વિગતવાર માહિતી ભરો',
    'lnk-translator':'ટાઇપિંગ માટે ગૂગલ અંગ્રેજીથી ગુજરાતી અનુવાદનો ઉપયોગ કરો',
    'heading-personal-details': 'વ્યક્તિગત માહિતી',
    'heading-horoscope-details': 'જન્માક્ષર માહિતી',
    'heading-education-details': 'શિક્ષણ અને વ્યવસાય',
    'heading-family-details': 'પરીવારની માહિતી',

    'heading-biodata': '|| શ્રી ગણેશાય નમ ||',
    'lbl-married': 'પરણિત',
    

    'lbl-fullname': 'પૂરું નામ',
    'lbl-castsubcast': 'જાતિ',
    'lbl-complexion':'રંગ',
    'lbl-dateofbirth':"જન્મ તારીખ",
    'lbl-height':"ઉંચાઇ",
    'lbl-blood':"રક્ત જૂથ",
    'lbl-diet':"આહાર",
    'lbl-timeofbirth':"જન્મ સમય",
    'lbl-placeofbirth':"જન્મ સ્થળ",
    'lbl-rashi':"રાશી",
    'lbl-devak':"દેવક / કુલદૈવત / ગોત્ર",
    'lbl-education-details':"શિક્ષણ માહિતી",
    'lbl-incomedetails':"નોકરી વ્યવસાય",
    'lbl-fathersfullname':"પિતાનું પૂરું નામ",
    'lbl-mothername':"માતાનું નામ",
    'lbl-grandfatherfullname':"દાદાનું પૂરું નામ",
    'lbl-contact1':"પિતાનું સંપર્ક નમ્બર",
    'lbl-contact2':"સંપર્ક નમ્બર २",
    'lbl-totbrothers': "કુલ ભાઈઓ",
    'lbl-marriedbrothers':"પરણિત ભાઈઓ",
    'lbl-brothersfullname':"ભાઈઓનું પૂરું નામ",
    'lbl-totsisters':"કુલ બહેનો",
    'lbl-marriedsisters':"પરિણીત બહેનો",
    'lbl-sistersfullname':"બહેનોનું પૂરું નામ",
    'lbl-raddress':"રહેઠાણનું સરનામું",
    'lbl-tehasildistrict':"તાલુકા અને જિલ્લો",
    'lbl-fatheroccupation':"પિતાનો વ્યવસાય",
    'lbl-maternalname':"મામાનું પૂરું નામ",
    'lbl-paternalname':"કાકીનું પૂરું નામ",
    'lbl-surname':"સંબંધીઓની અટક",
    'lbl-expectationfrompartner':"જીવનસાથી પાસેથી અપેક્ષાઓ",


    'lbl-birthdate':"જન્મ તારીખ",
    'lbl-birthtime':"જન્મ સમય",
    'lbl-birthplace':"જન્મ સ્થળ",
    'lbl-devakgotra':"કુલદૈવત",
    'lbl-cast':"જાતિ",
    'lbl-education':"શિક્ષણ",
    'lbl-occupation':"વ્યવસાય",
    'lbl-familydetails':"કૌટુંબિક વિગતો",
    'lbl-grandfather':"દાદા",
    'lbl-fathersname':"પિતાનું નામ",
    'lbl-fathersoccp':"પિતાનો વ્યવસાય",
    'lbl-brothers':"ભાઈઓ",
    'lbl-sisters':"બહેનો",
    'lbl-mamasname':"મામાનું નામ",
    'lbl-auntname':"કાકીનું નામ",
    'lbl-relatives':"સંબંધીઓ",
    'lbl-expectations':"અપેક્ષાઓ",
    'lbl-parentscont':"માતા-પિતાની કન્ટેન્ટ",
    'lbl-address':"સરનામું",

    'lbl-ft':"ફૂટ",
    'lbl-in':"ઇંચ",

    'option-fair':"ફેર",
    'option-nimgora':"નિમગોરા",
    'option-sawala':"સવાલા",
    'option-wheatish':"ઘઉં જેવું",

    'option-mesh':"જાળીદાર",
    'option-vrushabh':"વૃષભ",
    'option-mithun':"મિથુન",
    'option-kark':"કર્ક",
    'option-sinh':"સિંહ",
    'option-kanya':"કન્યા",
    'option-tula':"તુલા",
    'option-vrushchik':"વ્રુશ્ચિક",
    'option-dhanu':"ધનુ",
    'option-makar':"મકર",
    'option-kumbha':"કુંભા",
    'option-meen':"મીન",

    'option-ap':"એ+",
    'option-am':"એ-",
    'option-bp':"બી+",
    'option-bm':"બી-",
    'option-abp':"એબી+",
    'option-abm':"એબી-",
    'option-op':"ઓ+",
    'option-om':"ઓ-",

    'option-veg':"શાકાહારી",
    'option-nonveg':"માંસાહારી",

    'lbl-am':"એએમ",
    'lbl-pm':"પીએમ",
  }
};