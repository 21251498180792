import React from 'react';
import { useSelector } from 'react-redux';

import { Row } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CONSTANTS } from '../../constants/app';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function BiodataTemplate(){

    const biodataPreviewTemplate = useSelector((state)=>state.biodataSlice.biodataPreviewTemplate)
    const biodataPreviewTemplateWithPhoto = useSelector((state)=>state.biodataSlice.biodataPreviewTemplateWithPhoto)

    const bioFormatsWithPhoto = biodataPreviewTemplateWithPhoto.map((item,i) => {
      return (
        <div key={i}>
          <img
            className="d-block w-100"
            src={item['img']}
            alt={item['alt']}
            style={{"padding":"5px"}}
          />
        </div>
    )
  });

    const bioFormats = biodataPreviewTemplate.map((item,i) => {
        return (
          <div key={i}>
            <img
              className="d-block w-100"
              src={item['img']}
              alt={item['alt']}
              style={{"padding":"5px"}}
            />
          </div>
      )
    });

    

    return(
        <>
          <Row>
            <h2 className="heading">{CONSTANTS.BIODATA.TemplateIdeas}</h2>
            <h2 className="heading">{CONSTANTS.BIODATA.headingTemplatewithphoto}</h2>
            <Carousel responsive={responsive} autoPlay={true} infinite={true}>
              {bioFormatsWithPhoto}
            </Carousel>
          </Row>
          <hr/>
          <Row>
            <h2 className="heading">{CONSTANTS.BIODATA.headingTemplate}</h2>
            <Carousel responsive={responsive} autoPlay={true} infinite={true}>
              {bioFormats}
            </Carousel>
          </Row>
          <hr/>
        </>
  );
}