

export function numberToNumber(language,input) {
    
    const english = ["0","1","2","3","4","5","6","7","8","9"];
    const marathi = ["०","१","२","३","४","५","६","७","८","९"];
    const gujarati = ["૦","૧","૨","૩","૪","૫","૬","૭","८","૯"];
    const benaali = ["0","૧","২","৩","৪","৫","৬","৭","৮","৯"];
    const tamil = ["௦","௧","௨","௩","௪","௫","௬","௭","௮","௯"];
    const telugu = ["౦","౧","౨","౩","౪","౫","౬","౭","౮","౯"];
    
    const newArr = input;
    const len = newArr.length;
    let tempNo = "";
    if(language === 'marathi' || language === 'hindi'){
        for (let index = 0; index < len; index++) {
            const element = marathi[newArr[index]];
            if(element)
            tempNo = tempNo+element;
        }
    }else if(language === 'gujarati'){
        for (let index = 0; index < len; index++) {
            const element = gujarati[newArr[index]];
            if(element)
            tempNo = tempNo+element;
        }
    }else if(language === 'benaali'){
        for (let index = 0; index < len; index++) {
            const element = benaali[newArr[index]];
            if(element)
            tempNo = tempNo+element;
        }
    }else if(language === 'tamil'){
        for (let index = 0; index < len; index++) {
            const element = tamil[newArr[index]];
            if(element)
            tempNo = tempNo+element;
        }
    }else if(language === 'telugu'){
        for (let index = 0; index < len; index++) {
            const element = telugu[newArr[index]];
            if(element)
            tempNo = tempNo+element;
        }
    }else{
        for (let index = 0; index < len; index++) {
            const element = english[newArr[index]];
            if(element)
            tempNo = tempNo+element;
        }
    }
    return tempNo;
}



export function generateRange(start, end){
    let numbers = [];
    for(let no = start; no <= end; no++){
        numbers.push(no)
    }
    return numbers;
}

export function formatData(arr){
    const data =  arr.map((item,i) => {
        if (item.toString().length === 1) {
            item = "0" + item;
        }
        return (<option  key={i}>{item}</option>)
    });
    return data;
}

export function validateForm() {
    var form = document.getElementById("shaadibiodataform");
    var inputs = form.getElementsByTagName("input"), input = null, not_pass = null,ele = null;

    for(let i = 0, len = inputs.length; i < len; i++) {
        input = inputs[i];
        ele = document.querySelector('form input[name="'+input.name+'"]');

        if(input.type === "text" && !input.value) {
            addClass(ele,'error');
        } 

        if(input.type === "text" && input.value){
            removeClass(ele,'error');
        }

        if(input.type === "number" && !input.value) {
            addClass(ele,'error');
        } 
        
        if(input.type === "number" && input.value){
            removeClass(ele,'error');
        }

        //profile photo
        if(input.name === "myFile"){
            removeClass(ele,'error');
        }

    }

    not_pass = form.getElementsByClassName('error').length;

    if (not_pass) {
        alert("Please fill all the data which are highlighted by red border.")
        return false;
    } else {
        return true;
    }
}

function hasClass(el, className)
{
    if (el.classList)
        return el.classList.contains(className);
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

function addClass(el, className)
{
    if (el.classList)
        el.classList.add(className)
    else if (!hasClass(el, className))
        el.className += " " + className;
}

function removeClass(el, className)
{
    if (el.classList)
        el.classList.remove(className)
    else if (hasClass(el, className))
    {
        var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        el.className = el.className.replace(reg, ' ');
    }
}

export function hideReviewCompany(){
    //hide review section company mark
    if(document.querySelector('div[data-app="eapps-google-reviews"] > div > a')){
        document.querySelector('div[data-app="eapps-google-reviews"] > div > a').style.opacity = 0;
    }
}