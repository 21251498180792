import React from 'react';
import {createRoot} from 'react-dom/client';

import  store  from "./state/store";
import { Provider } from "react-redux";

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './responsive.scss';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);