import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '../../stories/Button';
import { jsPDF } from "jspdf";

import "./success.scss";

export default function Success(){
    const finalImage = localStorage.getItem("finalImage");
    const downloadPDF = () => {
        const data = localStorage.getItem("finalImage");
        const doc = new jsPDF();
        doc.addImage(data, 'PNG', 25, 25);
        doc.save('shaadibiodata_in.pdf');
    }
    
    return(
        <section className='success-container'>
            <Container>
                <Row>
                    <div className="heading success">Payment Successfull</div>
                </Row>
                <Row>
                    <div className="mid-heading mt-3 mb-2">Download your marriage biodata in PNG format.</div>
                    <Col lg={3} md={3} xs={12}>
                         <a href={finalImage} download="shaadibiodata_in" className="storybook-button storybook-button--small">Download</a>
                    </Col>
                </Row>
                <Row>
                    <div className="mid-heading mt-3 mb-2">Download your marriage biodata in PDF fromat.</div>
                    <Col lg={3} md={3} xs={12}>
                        <Button onClick={(e)=>downloadPDF(e)}>Download</Button>
                    </Col>
                </Row>
            </Container>
       </section>
    );
}