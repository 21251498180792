import { LOCALES } from '../locales';

export default {
  [LOCALES.HINDI]: {

    'biodata-main-heading': 'चरण २ : कृपया आकर्षक बायोडाटा बनाने के लिए हिंदी कीबोर्ड इस्तेमाल करे ओर विस्तृत जानकारी भरे',
    'lnk-translator':'टाइपिंग के लिए गूगल अंग्रेजी से हिंदी अनुवाद का उपयोग करें',
    'heading-personal-details': 'निजी विवरण',
    'heading-horoscope-details': 'जन्म कुंडली विवरण',
    'heading-education-details': 'शिक्षा ओर व्यावसाय विवरण',
    'heading-family-details': 'पारिवारिक विवरण',

    'heading-biodata': 'परिचय',
    'lbl-married': 'विवाहित',
    

    'lbl-fullname': 'पूरा नाम',
    'lbl-castsubcast': 'जाति',
    'lbl-complexion':'रंग',
    'lbl-dateofbirth':"जन्म दिनांक",
    'lbl-height':"लंबाई",
    'lbl-blood':"रक्तग्रुप",
    'lbl-diet':"आहार",
    'lbl-timeofbirth':"जन्म समय",
    'lbl-placeofbirth':"जन्म स्थान",
    'lbl-rashi':"राशी",
    'lbl-devak':"गोत्र",
    'lbl-education-details':"शिक्षा विवरण",
    'lbl-incomedetails':"व्यावसाय विवरण ओर स्थान",
    'lbl-fathersfullname':"पिता का पूरा नाम",
    'lbl-mothername':"मां का नाम",
    'lbl-grandfatherfullname':"दादाजी का नाम",
    'lbl-contact1':"पिताजी का संपर्क नंबर १",
    'lbl-contact2':"संपर्क नंबर २",
    'lbl-totbrothers': "भाई संख्या",
    'lbl-marriedbrothers':"शादीशुदा भाई",
    'lbl-brothersfullname':"भाई का नाम",
    'lbl-totsisters':"बेहन संख्या",
    'lbl-marriedsisters':"शादीशुदा बेहने",
    'lbl-sistersfullname':"बेहन का नाम",
    'lbl-raddress':"आवासीय पता",
    'lbl-tehasildistrict':"तालुका और जीला",
    'lbl-fatheroccupation':"पिताजी का व्यावसाय",
    'lbl-maternalname':"मामा का पूरा नाम",
    'lbl-paternalname':"बुआ का नाम",
    'lbl-surname':"रिश्तेदारों का उपनाम",
    'lbl-expectationfrompartner':"पार्टनर से उम्मीदें",


    'lbl-birthdate':"जन्म तिथि",
    'lbl-birthtime':"जन्म समय",
    'lbl-birthplace':"जन्म स्थान",
    'lbl-devakgotra':"कुलदैवत",
    'lbl-cast':"जाति",
    'lbl-education':"शिक्षा विवरण",
    'lbl-occupation':"व्यावसाय विवरण",
    'lbl-familydetails':"पारिवारिक विवरण",
    'lbl-grandfather':"दादा",
    'lbl-fathersname':"पिताजी नाम",
    'lbl-fathersoccp':"पिताजी का व्यावसाय",
    'lbl-brothers':"भाई",
    'lbl-sisters':"बेहने",
    'lbl-mamasname':"मामा का नाम",
    'lbl-auntname':"बुआ का नाम",
    'lbl-relatives':"रिश्तेदार",
    'lbl-expectations':"उम्मीदें",
    'lbl-parentscont':"संपर्क नंबर",
    'lbl-address':"पता",

    'lbl-ft':"फूट",
    'lbl-in':"इंच",

    'option-fair':"गोरा",
    'option-nimgora':"निमगोरा",
    'option-sawala':"सावळा",
    'option-wheatish':"गहूवर्ण",

    'option-mesh':"मेष",
    'option-vrushabh':"वृषभ",
    'option-mithun':"मिथुन",
    'option-kark':"कर्क",
    'option-sinh':"सिंह",
    'option-kanya':"कन्या",
    'option-tula':"तुला",
    'option-vrushchik':"वॄश्चिक",
    'option-dhanu':"धनु",
    'option-makar':"मकर",
    'option-kumbha':"कुम्भ",
    'option-meen':"मीन",

    'option-ap':"ए+",
    'option-am':"ए-",
    'option-bp':"बी+",
    'option-bm':"बी-",
    'option-abp':"एबी+",
    'option-abm':"एबी-",
    'option-op':"ओ+",
    'option-om':"ओ-",

    'option-veg':"शाकाहारी",
    'option-nonveg':"मांसाहारी",

    'lbl-am':"AM",
    'lbl-pm':"PM",
  }
};