import React from 'react';
import PropTypes from 'prop-types';
import './input.scss';

/**
 * Primary UI component for user interaction
 */
export const Input = ({ primary, size, label,type,className, ...props }) => {
  return (
    <input
      type={type}
      className={['storybook-input', `storybook-input--${size}`,`${className?className:''}`].join(' ')}
      {...props}
      autoComplete="off"
    />
  );
};

Input.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
 
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Input.defaultProps = {
  primary: false,
  size: 'small',
  onClick: undefined,
};
