import { LOCALES } from '../locales';
export default {
  [LOCALES.MARATHI]: {
    'biodata-main-heading': 'पायरी २ : कृपया मराठी बायोडाटा बनवण्यासाठी मराठी कीबोर्ड वापरा आणि विस्तृत माहिती भरा',
    'lnk-translator':'टायपिंगसाठी गुगल इंग्रजी ते मराठी भाषांतर वापरा',
    'heading-personal-details': 'स्वतःबद्दल माहिती',
    'heading-horoscope-details': 'जन्मकुंडली माहिती',
    'heading-education-details': 'शैक्षणिक व व्यावसायिक माहिती',
    'heading-family-details': 'कौटुंबिक माहिती',

    'heading-biodata': '॥ श्री गणेशाय नमः ॥',
    'lbl-married': 'विवाहित',

    'lbl-fullname': 'संपूर्ण नाव',
    'lbl-castsubcast': 'जात',
    'lbl-complexion':'वर्ण',
    'lbl-dateofbirth':"जन्म तारीख",
    'lbl-height':"उंची",
    'lbl-blood':"रक्तगत",
    'lbl-diet':"आहार",
    'lbl-timeofbirth':"जन्म वेळ",
    'lbl-placeofbirth':"जन्म ठिकाण",
    'lbl-rashi':"राशी",
    'lbl-devak':"देवक / कुलदैवत / गोत्र ",
    'lbl-education-details':"शैक्षणिक माहिती",
    'lbl-incomedetails':"नोकरी व्यवसाय",
    'lbl-fathersfullname':"वडिलांचे संपूर्ण नाव",
    'lbl-mothername':"आईचे नाव",
    'lbl-grandfatherfullname':"आजोबांचे संपूर्ण नाव",
    'lbl-contact1':"पालकांचा संपर्क क्रमांक",
    'lbl-contact2':"संपर्क क्रमांक २",
    'lbl-totbrothers': "एकुण भाऊ",
    'lbl-marriedbrothers':"विवाहित भाऊ",
    'lbl-brothersfullname':"भावांची संपूर्ण नावे",
    'lbl-totsisters':"एकुण बहिणी",
    'lbl-marriedsisters':"विवाहित बहिणी",
    'lbl-sistersfullname':"बहिणींची संपूर्ण नावे",
    'lbl-raddress':"राहण्याचा पत्ता",
    'lbl-tehasildistrict':"तालुका आणि जिह्वा",
    'lbl-fatheroccupation':"वडिलांचा नोकरी व्यवसाय",
    'lbl-maternalname':"मामाचे संपूर्ण नाव",
    'lbl-paternalname':"आत्याचे संपूर्ण नाव",
    'lbl-surname':"नातेवाईकांचे आडनाव",
    'lbl-expectationfrompartner':"स्थळाकडुन अपेक्षा",

    'lbl-birthdate':"जन्म तारीख",
    'lbl-birthtime':"जन्म वेळ",
    'lbl-birthplace':"जन्म ठिकाण",
    'lbl-devakgotra':"देवक / कुलदैवत",
    'lbl-cast':"जात",
    'lbl-education':"शिक्षण",
    'lbl-occupation':"नोकरी व्यवसाय",
    'lbl-familydetails':"कौटुंबिक माहिती",
    'lbl-grandfather':"आजोबांचे नाव",
    'lbl-fathersname':"वडिलांचे नाव",
    'lbl-fathersoccp':"वडिलांचा व्यवसाय",
    'lbl-brothers':"भाऊ",
    'lbl-sisters':"बहिणी",
    'lbl-mamasname':"मामाचे नाव",
    'lbl-auntname':"आत्याचे नाव",
    'lbl-relatives':"नातेवाईक",
    'lbl-expectations':"अपेक्षा",
    'lbl-parentscont':"संपर्क क्रमांक",
    'lbl-address':"राहण्याचा पत्ता",

    'lbl-ft':"फूट",
    'lbl-in':"इंच",

    'option-fair':"गोरा",
    'option-nimgora':"निमगोरा",
    'option-sawala':"सावळा",
    'option-wheatish':"गहूवर्ण",

    'option-mesh':"मेष",
    'option-vrushabh':"वृषभ",
    'option-mithun':"मिथुन",
    'option-kark':"कर्क",
    'option-sinh':"सिंह",
    'option-kanya':"कन्या",
    'option-tula':"तुला",
    'option-vrushchik':"वॄश्चिक",
    'option-dhanu':"धनु",
    'option-makar':"मकर",
    'option-kumbha':"कुम्भ",
    'option-meen':"मीन",

    'option-ap':"ए+",
    'option-am':"ए-",
    'option-bp':"बी+",
    'option-bm':"बी-",
    'option-abp':"एबी+",
    'option-abm':"एबी-",
    'option-op':"ओ+",
    'option-om':"ओ-",

    'option-veg':"शाकाहारी",
    'option-nonveg':"मांसाहारी",

    'lbl-am':"AM",
    'lbl-pm':"PM",
  }
};