import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import BiodataTemplate from './BiodataTemplate';
import Languages from './Languages';
import BiodataForm from './BiodataForm';
import Preview from './preview';

//Language provider
import { I18nProvider, LOCALES } from '../../i18n';

import "./index.scss";

export default function Index(props){

    const actLanguage = useSelector((state)=>state.biodataSlice.actLanguage)

    console.log(actLanguage)
    return(
        <section>
            <Container>
                <BiodataTemplate />
                <Languages />
                {/* Language wrapper for the app and default language is english */}
                <I18nProvider locale={LOCALES[actLanguage.name.toUpperCase()]}>
                    <BiodataForm />
                </I18nProvider>
                <Preview updateBiodataCount={props.updateBiodataCount}/>
            </Container>
        </section>
    )
}