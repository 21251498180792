import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { CONSTANTS } from '../../constants/app';
import { IMAGES } from '../../constants/images';
import { Button } from '../../stories/Button';
import {Input} from "../../stories/Input";

import {APP_API_BASEURL} from "../../config";

import "./contact.scss";

export default function Contact(){

    const mySubmitHandler = (e) => {
        e.preventDefault();

        let fname = document.getElementById("fname").value;
        let email = document.getElementById("email").value;
        let cnumber = document.getElementById("cnumber").value;
        let subject = document.getElementById("subject").value;

        const url = APP_API_BASEURL+'api/submit_contact_form.php';

          fetch(url,{
              method:'POST',
              headers:{
                  'Accept':'application/json',
                  'Content-Type':'application/json',
                  'X-Requested-With':'XMLHttpRequest',
              },
              body: JSON.stringify({
                fname:fname,
                email:email,
                cnumber:cnumber,
                subject:subject
              })
          })
          // .then((res) =>res.json())
          .then((data) => {
            //reset form
            console.log(data)
            document.getElementsByTagName('form')[0].reset();
            alert("Thanks for the contact, we will back to you within 24 hours.");
          })
    }

    return(
      <section>
        <Container>
            <Row>
                <div className="heading">{CONSTANTS.HEADER.lblContactUs}</div>
            </Row>
            <Row className="contact">
              <div className="col-md-5">
                <img src={IMAGES.map} width="370" alt="shaadibiodata map"/>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-6">
                  <form onSubmit={(e)=>mySubmitHandler(e)}>
                    <Input type="text" id="fname" name="firstname" placeholder="Your name.." required/>
                    <Input type="email" id="email" name="email" placeholder="Your email.." required/>
                    <Input type="number" id="cnumber" name="cnumber" placeholder="Your contact number.." required/>
                    <Input id="subject" name="subject" placeholder="Write your queries here.." required/>
                    <Button type="submit">Submit</Button>
                  </form>
              </div>
            </Row>
        </Container>
    </section>
  );
}
