import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useDispatch } from 'react-redux';

import Header from './components/header/header';
import Footer from './components/footer/footer';
import Help from './components/help/help';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Biodata from './components/biodata/index';
import Report from './components/report/report';
import Success from './components/success/success';

import {APP_API_BASEURL} from "./config";

import { setTotalBiodataCreated } from "./state/biodataSlice";
import { useEffect } from "react";



function App() {
  
  const dispatch = useDispatch();

  const updateBiodataCount = () => {

    console.log(APP_API_BASEURL)
    const url = APP_API_BASEURL +'api/get_last_biodata.php';

    fetch(url,{
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'X-Requested-With':'XMLHttpRequest',
        },
        body: JSON.stringify({})
    })
    .then((res) =>res.json())
    .then((data) => {
        console.log(data);
        dispatch(setTotalBiodataCreated(data['count']))
        
    })

    
    
  }

  useEffect(()=>{
    updateBiodataCount();
  })

  //Disable right click
  useEffect(() => {
      const handleContextmenu = e => {
          e.preventDefault()
      }
      document.addEventListener('contextmenu', handleContextmenu)
      return function cleanup() {
          document.removeEventListener('contextmenu', handleContextmenu)
      }
  },[])
  
  return (
    <Router>
      <Header/>
        <Routes>
            <Route exact path="/" element={<Biodata/>}/>
            <Route exact path="/home" element={<Biodata updateBiodataCount={updateBiodataCount}/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/contact" element={<Contact/>}/>
            <Route exact path="/help" element={<Help/>}/>
            <Route exact path="/success" element={<Success/>}/>
        </Routes>
      <Report/> 
      <Footer/> 
    </Router>
  );
}

export default App;
