import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import "./report.scss";

export default function Report(){
    const payForBiodata = useSelector((state)=>state.biodataSlice.totalBiodataCreated);
    return(
        <section>
            <Container>
                <Row className='report'>
                    <Col>
                        <p className="count">100%</p>
                        <p>Satisfied Customers</p>
                    </Col>
                    <Col xs={1}><div className="line-container"></div></Col>
                    <Col>
                        <p className="count">100%</p>
                        <p>Secure</p>
                    </Col>
                    <Col xs={1}><div className="line-container"></div></Col>
                    <Col>
                        <p className="count">{payForBiodata}</p>
                        <p>Biodata Downloaded</p>
                    </Col>
                </Row>
            </Container>
       </section>
    );
}